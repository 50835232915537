import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import {
  ChangesHistoryStore,
  CouponsStore,
  DiscountsStore,
  GatewaysStore,
  NotesStore,
  OrdersOnHoldStore,
  PDFIssuesStore,
  ProcurementStore,
  ProductsStore,
  SSOIssuesStore,
  SubGroupsStore,
  VendorsStore,
} from './actions';
import { environment } from 'environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ErrorHandlerService } from './services/error-handler.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
    NgxsModule.forRoot(
      [
        VendorsStore,
        ChangesHistoryStore,
        ProductsStore,
        NotesStore,
        ProcurementStore,
        CouponsStore,
        GatewaysStore,
        DiscountsStore,
        SSOIssuesStore,
        SubGroupsStore,
        PDFIssuesStore,
        OrdersOnHoldStore,
      ],
      {
        developmentMode: !environment.production,
      },
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }), // SHOULD BE THE LAST IMPORT !!
  ],
  providers: [
    ErrorHandlerService,
  ],
})
export class StoreModule {
}
